$(function () {
    setHeaderScroll();
    hamburger();
    scrollTevealInit();

});

$(document).ready(function() {
    //sub-menu
    var button_text = "Zobrazit kategorie";
    $('#arts_filter-mobile-trigger').on('click', function (e) {
        e.preventDefault();
        $(this).text(
            button_text == "Skrýt filtr" ? "Zobrazit filtr" : "Skrýt filtr"
        );
        // $(this).toggleClass('active');
        $('#arts_filter').slideToggle(200);
        button_text = $(this).text();
    });

    $('#mainFilter input[type=checkbox], #mainFilter input[type=radio]').on('change', function () {
        var $form = $(this).closest('form');
        $form.request();
    });
});
